<template>
  <v-sheet class="my-7">
    <v-card elevation="7" class="pa-lg-16 pa-8">
      <p
        class="text-lg-h5 text-sm-h6 mb-0 animate__animated animate__fadeInRight"
      >
        Receive an impressive report card this time with our home tuition service.
        Let our professional tutors guide your child. Let us deliver the results
        to you.
      </p>

      <NuxtLink class="text-white fw-bold" to="/form">
        <v-btn
          color="green-darken-3"
          size="large"
          class="my-5 px-lg-4"
          elevation="2"
          variant="flat"
        >
          Get Started
        </v-btn>
      </NuxtLink>
    </v-card>
    <section>
      <div class="shadow-one testimony bg-white">
        <div class="text-center"></div>
        <div class="text-center"></div>
      </div>
    </section>
  </v-sheet>
</template>

<script>
export default {
  name: "word",
};
</script>

<style scoped></style>
